type TimeUnit = 'year' | 'month' | 'day';

export default function getTimesAgo({
  now,
  amount = 1,
  type,
  isSeconds = true,
}: {
  now: Date;
  amount?: number;
  type: TimeUnit;
  isSeconds?: boolean;
}): number {
  let newDate: Date;

  switch (type) {
    case 'year':
      newDate = new Date(
        now.getFullYear() - amount,
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds(),
        now.getMilliseconds(),
      );
      break;
    case 'month':
      newDate = new Date(
        now.getFullYear(),
        now.getMonth() - amount,
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds(),
        now.getMilliseconds(),
      );
      break;
    case 'day':
      newDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - amount,
        now.getHours(),
        now.getMinutes(),
        now.getSeconds(),
        now.getMilliseconds(),
      );
      break;
    default:
      throw new Error('Invalid time unit');
  }

  if (isSeconds) {
    return Math.floor(newDate.getTime() / 1000);
  } else {
    return Math.floor(newDate.getTime());
  }
}
