import mqtt, { MqttProtocol } from 'mqtt';
import { ConfigSSI, getTokenMQTT, IResponseTokenMQTT, IStockSSI } from './api';
import { useCallback, useEffect, useState } from '../../lib/teact/teact';

export const getToken = async (): Promise<string | undefined> => {
  try {
    const response = (await getTokenMQTT()) as IResponseTokenMQTT;
    const dataTokenMQTT = response?.token;
    if (dataTokenMQTT) {
      localStorage.setItem('tokenMQTT', dataTokenMQTT);
    }
    return dataTokenMQTT;
  } catch (error) {
    console.error('Error getting MQTT token:', error);
    return undefined;
  }
};

const connectToMQTT = (token: string) => {
  return mqtt.connect(ConfigSSI.BROKER, {
    clean: true,
    clientId: ConfigSSI.CLIENT_ID,
    username: ConfigSSI.USERNAME,
    password: token,
    defaultProtocol: 'wss' as MqttProtocol,
    reconnectPeriod: 0,
    keepalive: 120,
    rejectUnauthorized: false,
    path: '/wss',
  });
};

const getClientMQTT = async () => {
  try {
    let tokenMQTT = localStorage.getItem('tokenMQTT') || (await getToken());
    if (!tokenMQTT) return;
    const client = connectToMQTT(tokenMQTT);
    if (!client) {
      tokenMQTT = await getToken();
      if (!tokenMQTT) return;

      return connectToMQTT(tokenMQTT);
    }
    return client;
  } catch (error) {
    console.error('Error getting MQTT client:', error);
  }
};

const useMQTTClient = (listSSI: string[], isSubTopMI?: boolean) => {
  const [dataTopMI, setDataTopMI] = useState<any>();
  const [dataEntrade, setDataEntraDe] = useState<IStockSSI>();
  const [listSubOld, setListSubOld] = useState<string[]>([]);
  const [client, setClient] = useState<any>(null);

  const refreshClient = useCallback(async () => {
    const tokenMQTT = await getToken();
    if (!tokenMQTT) return;

    const client = connectToMQTT(tokenMQTT);

    setClient(client);
  }, []);

  useEffect(() => {
    const connectClient = async () => {
      const client = await getClientMQTT();
      setClient(client);
    };

    connectClient();
  }, []);

  useEffect(() => {
    if (!client || !listSSI) return;
    if (!listSSI.length) return;

    const topicsToUnsubscribe = listSubOld.filter((topic) => !listSSI.includes(topic));

    topicsToUnsubscribe.forEach((topic) => {
      client.unsubscribe(`plaintext/quotes/stock/SI/${topic}`, { qos: 2 }, (err: any) => {
        if (err) {
          console.error('Failed to unsubscribe to ${topic}:', err);
        }
      });
      client.unsubscribe(`plaintext/quotes/stock/TP/${topic}`, { qos: 2 }, (err: any) => {
        if (err) {
          console.error('Failed to unsubscribe to TP ${topic}:', err);
        }
      });
    });

    const topicsToSubscribe = listSSI.filter((topic) => !listSubOld.includes(topic));
    console.log('listSSI', listSSI);
    topicsToSubscribe.forEach((topic) => {
      client.subscribe(`plaintext/quotes/stock/SI/${topic}`, { qos: 2 }, (err: any) => {
        if (err) {
          console.error(`Failed to subscribe to ${topic}:`, err);
        }
      });
      client.subscribe(`plaintext/quotes/stock/TP/${topic}`, { qos: 2 }, (err: any) => {
        if (err) {
          console.error(`Failed to subscribe to TP ${topic}:`, err);
        }
      });
    });

    setListSubOld(listSSI);
  }, [listSSI, client]);

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        console.log('connected');
        if (isSubTopMI) {
          client.subscribe(`plaintext/quotes/index/MI/+`, { qos: 2 }, (err: any) => {
            if (err) {
              console.error('Failed to subscribe to ${topic}:', err);
            }
          });
        }
      });

      client.on('error', (error: any) => {
        if (error.message.includes('Connection refused: Not authorized')) {
          console.error('Connection error: Not authorized');
          refreshClient();
        } else {
          console.error('Connection error:', error.message);
        }
        client.end();
      });

      client.on('reconnect', () => {
        console.log('Reconnecting');
      });

      client.on('close', async () => {
        console.log('disconnect :>> ', 'disconnect');
      });

      client.on('message', (topic: string, message: string) => {
        const payload = { topic, message: message.toString() };
        const dataMessPayload = JSON.parse(payload.message);
        if (dataMessPayload.symbol) {
          setDataEntraDe((prev: any) => {
            const lastData = prev?.[dataMessPayload?.symbol] || {};
            return {
              ...prev,
              [dataMessPayload.symbol]: {
                ...lastData,
                ...dataMessPayload,
              },
            };
          });
        } else {
          setDataTopMI((prev: any) => ({
            ...prev,
            [dataMessPayload.indexName]: dataMessPayload,
          }));
        }
      });
    }

    return () => {
      if (client) {
        client.end(true, () => {
          console.log('MQTT client disconnected');
        });
      }
    };
  }, [client]);

  return { dataTopMI, dataEntrade };
};

export default useMQTTClient;
