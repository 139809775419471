import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { BACKEND_URL, DNSE_URL, DNSE_USERNAME } from '../../config';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-null/no-null */
/* eslint-disable consistent-return */
import { axiosClient } from '../axiosClient';

export const ConfigSSI = {
  BROKER: DNSE_URL,
  PORT: 443,
  CLIENT_ID: `nodejs-json-mqtt-ws-sub-${uuidv4()}`,
  USERNAME: DNSE_USERNAME,
  PASSWORD: '',
  FIRST_RECONNECT_DELAY: 1000,
  RECONNECT_RATE: 2,
  MAX_RECONNECT_COUNT: 12,
  MAX_RECONNECT_DELAY: 60000,
  updatePassword(newPassword: string) {
    this.PASSWORD = newPassword;
  },
};

export const colors = {
  FL: 'color-fl',
  CE: 'color-ce',
  UP: 'color-up',
  DOWN: 'color-down',
  REF: 'color-ref',
};

export interface IResponseDataSSI {
  limit: number;
  offset: number;
  total: number;
  data: IItemDataSSI[];
}

export interface IResponseDataSSIDetail {
  limit: number;
  offset: number;
  total: number;
  data: IItemDataSSIDetail[];
}

interface groupVIP {
  id: string;
}

export interface IResponseDataVIPStock {
  limit: number;
  offset: number;
  total: number;
  data: groupVIP[];
}

export interface IItemDataSSIDetail {
  pageProps: {
    companyInfo: SSIDetail;
  };
}

export interface SSIDetail {
  address: string;
  businessPermit: string;
  businessTypeId: 1;
  capital: 252960000000;
  companyTypeName: string;
  contactPerson: string;
  contactPersonPosition: string;
  email: string;
  exchange: string;
  industryName: string;
  fax: string;
  foundPermit: string;
  fullName: string;
  fullNameEn: string;
  gicsIndustry: string;
  gicsIndustryGroup: string;
  gicsSector: string;
  gicsSubIndustry: string;
  id: 324;
  image: string;
  name: string;
  permanentAddress: string;
  phone: string;
  subIndustryName: string;
  symbol: string;
  taxCode: string;
  url: string;
}

export interface IResponseTokenMQTT {
  token: string;
}

export function getPingStyles(currentPrice: any, previousPrice: any) {
  if (currentPrice === previousPrice || isNaN(currentPrice) || isNaN(previousPrice)) {
    return;
  }
  const classPing = Number(currentPrice) > Number(previousPrice) ? 'ping-ssi-up' : 'ping-ssi-down';
  return { classPing };
}

export function formatNumber(num: any, type: NumberFormatType = NumberFormatType.Price): any {
  num = parseFloat(num);
  if (Number.isNaN(num) || num === null) {
    switch (type) {
      case NumberFormatType.ChangedRatio:
        return '0.00%';
      case NumberFormatType.Changed:
        return '0.00';
      case NumberFormatType.Quantity:
        return 0;
      default:
        return '-';
    }
  }
  switch (type) {
    case NumberFormatType.Changed:
      return `${num > 0 ? '+' : num < 0 ? '-' : ''}${Math.abs(num).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    case NumberFormatType.ChangedRatio:
      return `${num > 0 ? '+' : num < 0 ? '-' : ''}${Math.abs(num).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`;
    case NumberFormatType.Quantity:
      if (!num) return '0';
      return num.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

    case NumberFormatType.TotalVolume:
      if (num >= 1e9) {
        num /= 1e9;
        return `${num.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} Tỷ`;
      } else if (num >= 1e6) {
        num /= 1e6;
        return `${num.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} Tr`;
      } else if (num >= 1e3) {
        num /= 1e3;
        return `${num.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} Ng`;
      } else {
        return `${num.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      }
    case NumberFormatType.Volume:
      if (num >= 1e9) {
        num /= 1e9;
        return `${num.toLocaleString('en-US', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })}B`;
      } else if (num >= 1e6) {
        num /= 1e6;
        return `${num.toLocaleString('en-US', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })}M`;
      } else if (num >= 1e3) {
        num /= 1e3;
        return `${num.toLocaleString('en-US', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })}K`;
      } else {
        return num;
      }
    case NumberFormatType.None:
      return num.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    default:
      return num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  }
}

export interface IStockSSI {
  [key: string]: StockSSIData;
}

export interface StockSSIData {
  accumulatedVal: number;
  accumulatedVol: number;
  avgPrice: number;
  basicPrice: number;
  buyForeignQtty: number;
  ceilingPrice: number;
  changed: number;
  changedRatio: number;
  currentRoom: number;
  estimatedPrice: number;
  floorCode: string;
  floorPrice: number;
  highestPrice: number;
  lowestPrice: number;
  matchPrice: number;
  matchQtty: number;
  oddLotSecurityStatus: string;
  securityStatus: string;
  securityType: string;
  sellForeignQtty: number;
  symbol: string;
  tradingSession: keyof typeof tradingSessionId;
  tradingTime: string;
  bid: { price: number; qtty: number }[];
  ask: { price: number; qtty: number }[];
}

export enum tradingSessionId {
  OPEN = 'Mở cửa',
  CLOSED = 'Đóng cửa',
  NOON_BREAK = 'Nghỉ trưa',
  START_MIDSESSION_BREAK = 'Nghỉ trưa',
  DONE_MIDSESSION_BREAK = 'Nghỉ trưa',
  ATO = 'ATO',
  ATC = 'ATC',
  PUT_THROUGH = 'Thỏa thuận',
  CONTINUOUS = 'Liên tục',
  UNKNOWN_SESSION = 'N/A',
}

export enum NumberFormatType {
  Price = 'price',
  TotalVolume = 'totalVolume',
  Volume = 'volume',
  Quantity = 'quantity',
  Changed = 'changed',
  ChangedRatio = 'changedRatio',
  None = 'none',
}

// export interface IItemDataSSI {
//   name: string;
//   securityName: string;
//   stockNo: string;
//   type: string;
//   clientName: string;
//   clientNameEn: string;
//   code: string;
//   description: string;
//   exchange: string;
//   full_name: string;
//   marketCapPrev: number;
// }
export interface IItemDataSSI {
  id: string;
  symbol: string;
  floor: string;
  type: string;
  symbolType: string;
  groupType: string;
  isListed: boolean;
  listedDate: string;
  company: string;
  companyName: string;
  companyNameEng: string;
  idtelegram: string;
  companyNameVie: string;
  shortName: string;
  logo: string;
  name: string;
  indexCode: string;
  industry: string;
  subIndustry: string;
  sector: string;
  sectorIndex: string;
  industryId: string;
  subIndustryId: string;
  sectorId: string;
  sectorIndexId: string;
  gicsSector: string;
  gicsIndustryGroup: string;
  gicsIndustry: string;
  gicsSubIndustry: string;
}

export function clearDataStock(dataStock: any): any {
  if (dataStock == null) {
    return {};
  }

  return Object.keys(dataStock).reduce((acc: any, key) => {
    acc[key] = returnEmptyIfFalsy(dataStock[key]);
    return acc;
  }, {});
}

function returnEmptyIfFalsy(value: any): any {
  if (!value) {
    return '';
  }
  if (typeof value === 'string' && !Number.isNaN(Number(value))) {
    return Number(value);
  }
  return value;
}

export const getTokenMQTT = async (): Promise<any> => {
  const response = await axiosClient({
    method: 'GET',
    url: `${BACKEND_URL}/api/ssi/token`,
  });
  if (!response) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response?.metadata;
};

export const getDataSSI = async (): Promise<any> => {
  const body = {
    search: '',
    offset: 0,
    limit: 100000,
    sort_member: true,
  };
  const response = await axiosClient({
    method: 'GET',
    url: `${BACKEND_URL}/api/ssi/list`,
    body,
  });
  if (!response) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response?.metadata;
};

export const getSSINotCreate = async (): Promise<any> => {
  const response = await axiosClient({
    method: 'GET',
    url: `${BACKEND_URL}/api/telegram/group/new-stock`,
  });
  if (!response) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response?.metadata;
};

export const getSSIDetail = async (): Promise<any> => {
  const response = await axiosClient({
    method: 'GET',
    url: `${BACKEND_URL}/api/ssi/detail`,
    body: { limit: 2000, offset: 0, search: '' },
  });
  if (!response) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response?.metadata;
};

export const getListVIP = async (): Promise<any> => {
  // /api/telegram/id?id=100&type=vip&limit=100&offset=0'
  const response = await axiosClient({
    method: 'GET',
    url: `${BACKEND_URL}/api/telegram/id`,
    body: { limit: 2000, offset: 0, type: 'vip', id: '' },
  });
  if (!response) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response?.metadata;
};

export const getInfoGroup = async (idTelegram: string): Promise<any> => {
  const response = await axiosClient({
    method: 'POST',
    url: `${BACKEND_URL}/api/telegram/group/find`,
    body: {
      idTelegram,
      idSSI: '',
      limit: 1,
      offset: 0,
    },
  });
  if (!response) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response?.metadata;
};

export const createChannelAndGroup = async (formData: { idTelegram: string; subSSI: string[]; type: string; idSSI: string }): Promise<any> => {
  const response = await axiosClient({
    method: 'POST',
    url: `${BACKEND_URL}/api/telegram/group`,
    body: formData,
  });
  if (!response) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response;
};

export const getTrending = async (): Promise<any> => {
  const response = await axios.get(`${BACKEND_URL}/trending`);
  if (!response) {
    throw new Error(`HTTP error!`);
  }

  return await response;
};

export const getStockCardData = async ({
  from,
  to,
  symbol,
  resolution,
}: {
  from: string | number;
  to: string | number;
  symbol: string;
  resolution: '1' | '1H' | '1D' | '1W';
}): Promise<any> => {
  const params = {
    from,
    to,
    symbol,
    resolution,
  };
  const response = await axios.get(`${BACKEND_URL}/chart/v2/ohlcs/stock`, { params });
  if (!response) {
    throw new Error(`HTTP error!`);
  }

  return await response?.data;
};

export const getStatistic = async (symbol: string): Promise<any> => {
  const params = {
    symbol,
  };
  //   const response = await axios.get(`${BACKEND_URL}/statistic`, { params });
  const response = await axios.get(`https://services.entrade.com.vn/senses-api/statistic`, { params });
  if (!response) {
    throw new Error(`HTTP error!`);
  }

  return await response?.data;
};

export const getPerformanceHistory = async (symbol: string): Promise<any> => {
  const response = await axios.get(`https://services.entrade.com.vn/chart-api/symbols/${symbol}/performance-history`);
  if (!response) {
    throw new Error(`HTTP error!`);
  }

  return await response?.data;
};

export const getSymbolDetail = async (symbol: string): Promise<any> => {
  const response = await axios.get(`https://services.entrade.com.vn/market-api/tickers?symbol=${symbol}`);
  if (!response) {
    throw new Error(`HTTP error!`);
  }

  return await response?.data?.data[0];
};
