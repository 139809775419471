import '../../global/actions/initial';
import type { FC } from '../../lib/teact/teact';
import React, { memo, useEffect, useRef, useState, useCallback } from '../../lib/teact/teact';
import { getActions, withGlobal } from '../../global';
import type { GlobalState } from '../../global/types';
import { PLATFORM_ENV } from '../../util/windowEnvironment';
import useCurrentOrPrev from '../../hooks/useCurrentOrPrev';
import useElectronDrag from '../../hooks/useElectronDrag';
import useHistoryBack from '../../hooks/useHistoryBack';
import Transition from '../ui/Transition';
import './Home.scss';
import StockCard from './StockCard';
import { clearDataStock, getStockCardData, getTrending } from '../../api/stock/api';
import getTimesAgo from '../../util/getTimesAgo';
import useMQTTClient from '../../api/stock/mqttClient';

type StateProps = Pick<GlobalState, 'authState'>;

const Home: FC<StateProps> = ({ authState }) => {
  const { returnToAuthPhoneNumber, goToAuthQrCode } = getActions();
  const isMobile = PLATFORM_ENV === 'iOS' || PLATFORM_ENV === 'Android';

  const handleChangeAuthorizationMethod = useCallback(() => {
    if (!isMobile) {
      goToAuthQrCode();
    } else {
      returnToAuthPhoneNumber();
    }
  }, [isMobile, goToAuthQrCode, returnToAuthPhoneNumber]);

  useHistoryBack({
    isActive: (!isMobile && authState === 'authorizationStateWaitPhoneNumber') || (isMobile && authState === 'authorizationStateWaitQrCode'),
    onBack: handleChangeAuthorizationMethod,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  useElectronDrag(containerRef);

  const renderingAuthState = useCurrentOrPrev(authState !== 'authorizationStateReady' ? authState : undefined, true);

  const getActiveKey = useCallback(() => {
    switch (renderingAuthState) {
      case 'authorizationStateWaitCode':
        return 0;
      case 'authorizationStateWaitPassword':
        return 1;
      case 'authorizationStateWaitRegistration':
        return 2;
      case 'authorizationStateWaitPhoneNumber':
        return 3;
      case 'authorizationStateWaitQrCode':
        return 4;
      default:
        return isMobile ? 3 : 4;
    }
  }, [renderingAuthState, isMobile]);

  const [trendingSymbol, setTrendingSymbol] = useState<string[]>([]);
  const [chartData, setChartData] = useState<any>({});

  useEffect(() => {
    const fetchTrending = async () => {
      try {
        const response = await getTrending();
        setTrendingSymbol(response.data);
      } catch (error) {
        console.error('Error fetching trending data:', error);
      }
    };
    fetchTrending();
  }, []);

  useEffect(() => {
    if (!trendingSymbol.length) return;

    const fetchChartData = async () => {
      const now = new Date();
      const nowTS = Math.floor(Date.now() / 1000);
      const from = getTimesAgo({ now, type: 'year', amount: 1, isSeconds: true });

      try {
        const responses = await Promise.all(trendingSymbol.map((symbol) => getStockCardData({ from, to: nowTS, symbol, resolution: '1W' })));
        const newChartData = responses.reduce((acc, data, index) => {
          acc[trendingSymbol[index]] = data;
          return acc;
        }, {});

        setChartData(newChartData);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchChartData();
  }, [trendingSymbol]);

  const { dataEntrade } = useMQTTClient(trendingSymbol || [], false);
  const dataSI = clearDataStock(dataEntrade);

  return (
    <Transition activeKey={getActiveKey()} name="fade" className="Auth" ref={containerRef}>
      <div>
        <section className="max-w-[1120px] mx-auto w-full bg-white lg:px-0 mb-15 md:mb-12.5">
          <div className="text-base md:text-xl font-medium mb-3">Top tìm kiếm</div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {chartData &&
              dataSI &&
              Object.keys(chartData).map((symbol) => <StockCard key={symbol} chart={chartData[symbol]} dataSI={dataSI[symbol]} />)}
          </div>
        </section>
      </div>
    </Transition>
  );
};

export default memo(
  withGlobal(
    (global): StateProps => ({
      authState: global.authState,
    }),
  )(Home),
);
