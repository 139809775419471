import type { FC } from '../lib/teact/teact';
import React, { useCallback, useEffect, useLayoutEffect } from '../lib/teact/teact';
import { getActions, withGlobal } from '../global';

import type { GlobalState } from '../global/types';
import type { ThemeKey } from '../types';
import type { UiLoaderPage } from './common/UiLoader';

import { CHAT_ID_GROUP_STOCK, DARK_THEME_BG_COLOR, INACTIVE_MARKER, LIGHT_THEME_BG_COLOR, PAGE_TITLE, USER_ADMIN_GROUP_STOCK } from '../config';
import { selectChat, selectTabState, selectTheme } from '../global/selectors';
import { addActiveTabChangeListener } from '../util/activeTabMonitor';
import buildClassName from '../util/buildClassName';
import { setupBeforeInstallPrompt } from '../util/installPrompt';
import { parseInitialLocationHash } from '../util/routing';
import { hasStoredSession } from '../util/sessions';
import { IS_INSTALL_PROMPT_SUPPORTED, IS_MULTITAB_SUPPORTED, PLATFORM_ENV } from '../util/windowEnvironment';
import { updateSizes } from '../util/windowSize';

import useAppLayout from '../hooks/useAppLayout';
import useFlag from '../hooks/useFlag';
import usePrevious from '../hooks/usePrevious';

import Auth from './auth/Auth';
import UiLoader from './common/UiLoader';
import AppInactive from './main/AppInactive';
import LockScreen from './main/LockScreen.async';
import Main from './main/Main.async';
import Transition from './ui/Transition';

import './App.module.scss';

import {
  createChannelAndGroup,
  getDataSSI,
  getListVIP,
  getSSIDetail,
  getSSINotCreate,
  IItemDataSSI,
  IResponseDataSSI,
  IResponseDataSSIDetail,
  IResponseDataVIPStock,
} from '../api/stock/api';
import { callApi } from '../api/gramjs';
import Home from './home/Home';
import { getToken } from '../api/stock/mqttClient';

type StateProps = {
  authState: GlobalState['authState'];
  isScreenLocked?: boolean;
  hasPasscode?: boolean;
  isInactiveAuth?: boolean;
  hasWebAuthTokenFailed?: boolean;
  theme: ThemeKey;
  global?: GlobalState;
  currentUserId?: string;
};

enum AppScreens {
  home,
  auth,
  main,
  lock,
  inactive,
  currentUserId,
}

const iconColors = [7322096, 9367192, 13338331, 16478047, 16766590];

const randomElementFromArray = (arr: number[]) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const TRANSITION_RENDER_COUNT = Object.keys(AppScreens).length / 2;
const INACTIVE_PAGE_TITLE = `${PAGE_TITLE} ${INACTIVE_MARKER}`;

async function fetchImageAsBlob(url: string): Promise<Blob> {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch image');
  }
  const blob = await response.blob();
  return blob;
}

function blobToFile(blob: Blob, fileName: string): File {
  const fileType = blob.type;
  return new File([blob], fileName, { type: fileType });
}

async function convertImageToSquare(blob: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const size = Math.max(img.width, img.height);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }

      canvas.width = size;
      canvas.height = size;

      // Fill the canvas with white color
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, size, size);

      const offsetX = (size - img.width) / 2;
      const offsetY = (size - img.height) / 2;

      ctx.drawImage(img, offsetX, offsetY);

      canvas.toBlob((newBlob) => {
        if (newBlob) {
          resolve(newBlob);
        } else {
          reject(new Error('Canvas toBlob failed'));
        }
      }, 'image/jpeg');
    };

    img.onerror = (err) => {
      reject(err);
    };

    img.src = URL.createObjectURL(blob);
  });
}

function convertImageToWhiteBackground(blob: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(img, 0, 0);

      canvas.toBlob((newBlob) => {
        if (newBlob) {
          resolve(newBlob);
        } else {
          reject(new Error('Canvas toBlob failed'));
        }
      }, 'image/jpeg');
    };

    img.onerror = (err) => {
      reject(err);
    };

    img.src = URL.createObjectURL(blob);
  });
}

const App: FC<StateProps> = ({ authState, isScreenLocked, hasPasscode, isInactiveAuth, hasWebAuthTokenFailed, theme, global, currentUserId }) => {
  const { disconnect } = getActions();
  const [isInactive, markInactive, unmarkInactive] = useFlag(false);
  const [isHome, markHome, unmarkHome] = useFlag(false);
  const { isMobile } = useAppLayout();
  const isMobileOs = PLATFORM_ENV === 'iOS' || PLATFORM_ENV === 'Android';

  useEffect(() => {
    if (IS_INSTALL_PROMPT_SUPPORTED) {
      setupBeforeInstallPrompt();
    }
  }, []);

  // Prevent drop on elements that do not accept it
  useEffect(() => {
    const body = document.body;
    const handleDrag = (e: DragEvent) => {
      e.preventDefault();
      if (!e.dataTransfer) return;
      if (e.dataTransfer.items.length === 0) {
        e.dataTransfer.dropEffect = 'move';
      } else if (!(e.target as HTMLElement).dataset.dropzone) {
        e.dataTransfer.dropEffect = 'none';
      } else {
        e.dataTransfer.dropEffect = 'copy';
      }
    };
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
    };
    body.addEventListener('drop', handleDrop);
    body.addEventListener('dragover', handleDrag);
    body.addEventListener('dragenter', handleDrag);

    return () => {
      body.removeEventListener('drop', handleDrop);
      body.removeEventListener('dragover', handleDrag);
      body.removeEventListener('dragenter', handleDrag);
    };
  }, []);

  let activeKey: number;
  let page: UiLoaderPage | undefined;

  if (isInactive) {
    activeKey = AppScreens.inactive;
  } else if (isHome) {
    activeKey = AppScreens.home;
  } else if (isScreenLocked) {
    page = 'lock';
    activeKey = AppScreens.lock;
  } else if (authState) {
    switch (authState) {
      case 'authorizationStateWaitPhoneNumber':
        page = 'authPhoneNumber';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitCode':
        page = 'authCode';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitPassword':
        page = 'authPassword';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitRegistration':
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitQrCode':
        page = 'authQrCode';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateClosed':
      case 'authorizationStateClosing':
      case 'authorizationStateLoggingOut':
      case 'authorizationStateReady':
        page = 'main';
        activeKey = AppScreens.main;
        break;
    }
  } else if (hasStoredSession()) {
    page = 'main';
    activeKey = AppScreens.main;
  } else if (hasPasscode) {
    activeKey = AppScreens.lock;
  } else {
    page = isMobileOs ? 'authPhoneNumber' : 'authQrCode';
    activeKey = AppScreens.auth;
  }

  if (
    activeKey !== AppScreens.lock &&
    activeKey !== AppScreens.inactive &&
    activeKey !== AppScreens.main &&
    parseInitialLocationHash()?.tgWebAuthToken &&
    !hasWebAuthTokenFailed
  ) {
    page = 'main';
    activeKey = AppScreens.main;
  }

  useEffect(() => {
    updateSizes();
  }, []);

  useEffect(() => {
    if (IS_MULTITAB_SUPPORTED) return;

    addActiveTabChangeListener(() => {
      disconnect();
      document.title = INACTIVE_PAGE_TITLE;

      markInactive();
    });
  }, [activeKey, disconnect, markInactive]);

  useEffect(() => {
    if (isInactiveAuth) {
      document.title = INACTIVE_PAGE_TITLE;
      markInactive();
    } else {
      document.title = PAGE_TITLE;
      unmarkInactive();
    }
  }, [isInactiveAuth, markInactive, unmarkInactive]);

  const fetchListSSINotCreate = useCallback(
    async (allStocks: IItemDataSSI[]) => {
      if (!global) return;
      console.log('allStocks', allStocks);
      try {
        const response = (await getSSINotCreate()) as unknown as any;
        const testSSIs = response.data;

        console.log('testSSIs', testSSIs);

        const chatId = CHAT_ID_GROUP_STOCK;
        const chat = selectChat(global, chatId);

        console.log('chat', chat);
        if (chat) {
          const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

          for (const testSSI of testSSIs) {
            const iconColor = randomElementFromArray(iconColors);
            const findDetail = allStocks.find((ssi) => ssi.symbol === testSSI.IdSSI);
            console.log('findDetail', findDetail);
            if (findDetail) {
              const { symbol, companyName, floor } = findDetail;
              console.log({
                chat: chat,
                title: `${symbol} - ${companyName} - ${floor}`,
              });

              try {
                const topicId = await callApi('createTopic', {
                  chat: chat,
                  title: `${symbol} - ${companyName} - ${floor}`,
                  iconColor: iconColor,
                  iconEmojiId: undefined,
                });

                if (topicId)
                  createChannelAndGroup({
                    idTelegram: `${topicId}`,
                    subSSI: [symbol],
                    type: 'chanel',
                    idSSI: symbol,
                  })
                    .then((resCreate) => {
                      const stringSSITelegram = localStorage.getItem('sllTelegramStocks');
                      if (stringSSITelegram) {
                        const listSSITeltegram = JSON.parse(stringSSITelegram);
                        localStorage.setItem('sllTelegramStocks', JSON.stringify([...listSSITeltegram, chatId]));
                      }
                    })
                    .catch((err) => {
                      console.error('Lỗi cập nhật đăng ký mã CK', err);
                    });

                console.log('create Topic', topicId);
              } catch (error) {
                console.error('Error creating topic:', error);
                createChannelAndGroup({
                  idTelegram: ``,
                  subSSI: [symbol],
                  type: 'chanel',
                  idSSI: symbol,
                })
                  .then((resCreate) => {
                    const stringSSITelegram = localStorage.getItem('sllTelegramStocks');
                    if (stringSSITelegram) {
                      const listSSITeltegram = JSON.parse(stringSSITelegram);
                      localStorage.setItem('sllTelegramStocks', JSON.stringify([...listSSITeltegram, chatId]));
                    }
                  })
                  .catch((err) => {
                    console.error('Lỗi cập nhật đăng ký mã CK', err);
                  });
              }
            }

            await delay(1500);
          }
        }

        // const { logoLink } = getLogoLink(inforSSI);

        // if (chatId && logoLink) {
        //   // if (logoLink) {
        //   const blob = await fetchImageAsBlob(logoLink);
        //   const squareBlob = await convertImageToSquare(blob);
        //   const photoWithWhiteBackground = await convertImageToWhiteBackground(squareBlob);
        //   const photoFile = blobToFile(photoWithWhiteBackground, `${symbol}.jpeg`);
        //   console.log(photoFile);
        //   if (photoFile) {
        //     const aa = await callApi('editChatPhoto', {
        //       chatId,
        //       photo: photoFile,
        //     });

        //   }

        //   const url = URL.createObjectURL(squareBlob);
        //   const img = document.createElement('img');
        //   img.src = url;
        //   img.alt = symbol;
        //   document.body.appendChild(img);
        // }

        // if (allSSIDetailString) {
        //   const listAllSSIDetail = JSON.parse(allSSIDetailString);
        //   const findDetail = listAllSSIDetail.find((i: SSIDetail) => i.symbol === symbol);
        //   if (!findDetail) {
        //     console.log('Ghi lốc không tìm thấy Deatil ', symbol);
        //     return;
        //   }
        //   const { fullName, email, url, industryName, phone } = findDetail;
        //   await callApi(
        //     'updateChatAbout',
        //     createdChat,
        //     `Tên Công ty: ${fullName || ''}\nEmail: ${email || ''}\nWebsite: ${url || ''}\nSố điện thoại: ${phone || ''}\nNgành: ${industryName || ''}`,
        //   );
        // }
      } catch (error) {
        console.error('Error fetchListSSINotCreate:', error);
      }
    },
    [global],
  );

  const fetchSSI = async () => {
    try {
      const response = (await getDataSSI()) as unknown as IResponseDataSSI;
      localStorage.setItem('allStocks', JSON.stringify(response.data));
      localStorage.setItem('sllTelegramStocks', JSON.stringify(response.data.filter((a) => a.idtelegram).map((b) => b.idtelegram)));
    } catch (error) {
      console.error('Error fetchSSI:', error);
    }
  };

  const fetchSSIDetail = async () => {
    try {
      const response = (await getSSIDetail()) as unknown as IResponseDataSSIDetail;

      const listSSIDetail = response.data.map((detail) => {
        const { id, fullName, symbol, email, url, industryName, address, phone, taxCode, fax } = detail.pageProps.companyInfo;
        return {
          id,
          symbol,
          fullName,
          email,
          url,
          industryName,
          address,
          phone,
          taxCode,
          fax,
        };
      });

      localStorage.setItem('allStocksDetail', JSON.stringify(listSSIDetail));
    } catch (error) {
      console.error('Error fetchSSI:', error);
    }
  };

  const checkUpdated = () => {
    const currentDate = new Date().toLocaleDateString();
    const storedDate = localStorage.getItem('today');

    const getListGroupVIP = async () => {
      try {
        const response = (await getListVIP()) as unknown as IResponseDataVIPStock;
        if (!response.data) return;

        const listIDTeleVip = response.data.map((vip) => vip.id);
        localStorage.setItem('listIDTeleVip', JSON.stringify(listIDTeleVip));
      } catch (error) {
        console.error('Error fetchSSI:', error);
      }
    };

    if (
      storedDate !== currentDate ||
      !localStorage.getItem('allStocks') ||
      !localStorage.getItem('allStocksDetail') ||
      !localStorage.getItem('sllTelegramStocks') ||
      !localStorage.getItem('tokenMQTT') ||
      !localStorage.getItem('listIDTeleVip')
    ) {
      localStorage.setItem('today', currentDate);
      getListGroupVIP();
      getToken();
      fetchSSI();
      fetchSSIDetail();
    }
  };

  useEffect(() => {
    const isUserAdmin = USER_ADMIN_GROUP_STOCK.includes(`${currentUserId}`);
    console.log('isUserAdmin', isUserAdmin);
    if (!isUserAdmin) return;
    const allStocks = localStorage.getItem('allStocks');
    console.log('allStocks', allStocks);

    if (!global || !allStocks) return;
    fetchListSSINotCreate(JSON.parse(allStocks));
  }, []);

  useEffect(() => {
    fetchSSI();

    checkUpdated();
  }, []);

  const prevActiveKey = usePrevious(activeKey);

  // eslint-disable-next-line consistent-return
  function renderContent() {
    switch (activeKey) {
      case AppScreens.auth:
        return <Auth />;
      case AppScreens.main:
        return <Main isMobile={isMobile} />;
      case AppScreens.lock:
        return <LockScreen isLocked={isScreenLocked} />;
      case AppScreens.inactive:
        return <AppInactive />;
      case AppScreens.home:
        return <Home />;
    }
  }

  useEffect(() => {
    const pageActive = localStorage.getItem('pageActive');
    if (pageActive === 'Home') markHome();
  }, [localStorage.getItem('pageActive')]);

  useLayoutEffect(() => {
    document.body.style.setProperty('--theme-background-color', theme === 'dark' ? DARK_THEME_BG_COLOR : LIGHT_THEME_BG_COLOR);
  }, [theme]);

  return (
    <UiLoader page={page} isMobile={isMobile}>
      <div className="flex h-full">
        <div className="flex flex-col relative h-full">
          <div
            className="p-4 my-2 cursor-pointer transition-colors duration-300 hover:bg-blue-500 hover:text-white"
            onClick={() => {
              markHome();
              localStorage.setItem('pageActive', 'Home');
            }}>
            Home
          </div>
          <div
            className="p-4 my-2 cursor-pointer transition-colors duration-300 hover:bg-blue-500 hover:text-white"
            onClick={() => {
              unmarkHome();
              localStorage.setItem('pageActive', 'Chat');
            }}>
            Chat
          </div>
        </div>
        <Transition
          name="fade"
          activeKey={activeKey}
          shouldCleanup
          className={buildClassName('full-height', (activeKey === AppScreens.auth || prevActiveKey === AppScreens.auth) && 'is-auth')}
          renderCount={TRANSITION_RENDER_COUNT}>
          {renderContent}
        </Transition>
      </div>
    </UiLoader>
  );
};

export default withGlobal((global): StateProps => {
  const { currentUserId } = global;
  return {
    authState: global.authState,
    isScreenLocked: global.passcode?.isScreenLocked,
    hasPasscode: global.passcode?.hasPasscode,
    isInactiveAuth: selectTabState(global).isInactive,
    hasWebAuthTokenFailed: global.hasWebAuthTokenFailed || global.hasWebAuthTokenPasswordRequired,
    theme: selectTheme(global),
    global,
    currentUserId: currentUserId,
  };
})(App);
