/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from '../../../lib/teact/teact';

import { NumberFormatType, colors, formatNumber, getPingStyles } from '../../../api/stock/api';

interface StockProps {
  currentPrice: any;
  referencePrice?: any;
  ceilingPrice?: any;
  floorPrice?: any;
  getColor?: any;
  isBlink?: boolean;
  type: NumberFormatType;
}

const StockPrice: React.FC<StockProps> = ({
  currentPrice,
  referencePrice,
  ceilingPrice,
  floorPrice,
  getColor,
  isBlink = false,
  type = NumberFormatType.Price,
}) => {
  const [previousPrice, setPreviousPrice] = useState<number>(currentPrice);
  const [color, setColor] = useState<string>('');
  const [classPing, setClassPing] = useState<string>('');

  useEffect(() => {
    if (currentPrice !== previousPrice) {
      setPreviousPrice(currentPrice);
    }
  }, [currentPrice]);

  useEffect(() => {
    if (referencePrice) {
      const conditions = [
        { condition: currentPrice > referencePrice, color: colors.UP },
        { condition: currentPrice < referencePrice, color: colors.DOWN },
        { condition: currentPrice === referencePrice, color: colors.REF },
        { condition: currentPrice === ceilingPrice, color: colors.CE },
        { condition: currentPrice === floorPrice, color: colors.FL },
      ];
      const matchedCondition = conditions.find(({ condition }) => condition);
      setColor(matchedCondition ? matchedCondition.color : '#d8d8d8');
    }
  }, [currentPrice, referencePrice, ceilingPrice, floorPrice]);

  useEffect(() => {
    if (!Number.isNaN(currentPrice) && currentPrice !== previousPrice) {
      const classPing = getPingStyles(Number(currentPrice), Number(previousPrice));
      if (classPing) {
        setClassPing(classPing.classPing);
      }
      setTimeout(() => {
        setClassPing('');
      }, 500);
    }
  }, [currentPrice]);

  useEffect(() => {
    if (getColor) {
      getColor(color);
    }
  }, [getColor, color]);

  return (
    <div>
      <p className={`${isBlink ? classPing : ''} align-center flex ${type !== NumberFormatType.Volume ? color : ''} px-1`}>
        {formatNumber(currentPrice, type)}
      </p>
    </div>
  );
};

export default StockPrice;
