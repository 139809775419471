/* eslint-disable no-null/no-null */
import axios, { Method } from 'axios';
import CryptoJS from 'crypto-js';
import { SECRET_KEY } from '../config';

function generateNonce(length: number): string {
  const array = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    array[i] = Math.floor(Math.random() * 256);
  }
  return Date.now().toString(16) + Array.from(array, (byte) => `0${byte.toString(16)}`.slice(-2)).join('');
}

function getTimestamp(): string {
  return Math.floor(Date.now() / 1000).toString();
}

type SignatureProps = {
  method: string;
  pathname: string;
  params: Record<string, any>;
  timestamp: string;
  nonce: string;
};

function createSignature({ method, pathname, params, timestamp, nonce }: SignatureProps): string {
  const keys = Object.keys(params).sort();
  let paramString = '';
  keys.forEach((key) => {
    paramString += key + params[key];
  });
  const path = pathname.replace('/api', '');
  const message = timestamp + nonce + method + path + paramString;

  const secretWordArray = CryptoJS.enc.Utf8.parse(SECRET_KEY);
  const messageWordArray = CryptoJS.enc.Utf8.parse(message);
  const hmac = CryptoJS.HmacSHA256(messageWordArray, secretWordArray);

  const signature = CryptoJS.enc.Hex.stringify(hmac);

  return signature;
}

type AxiosClientProps = {
  method: Method;
  url: string;
  body?: any;
};

export async function axiosClient({ method, url, body = {} }: AxiosClientProps): Promise<any> {
  const parsedUrl = new URL(url);

  const queryParams = parsedUrl.searchParams;
  const pathname = parsedUrl.pathname;

  const params: Record<string, string> = {};

  queryParams.forEach((value, key) => {
    params[key] = value;
  });

  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      params[key] = body[key];
    }
  }

  const nonce = generateNonce(16);
  const timestamp = getTimestamp();
  const signature = createSignature({
    method,
    pathname,
    params,
    timestamp,
    nonce,
  });

  const config = {
    method,
    url,
    headers: {
      'X-Nonce': nonce,
      'X-Timestamp': timestamp,
      'X-Signature': signature,
      'Content-Type': 'application/json',
    },
    params: method !== 'POST' ? params : {},
    data: body,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error sending request:', error);
    throw error;
  }
}
