import { useEffect, useState } from '../lib/teact/teact';

const useLoadChartScript = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/chart.js';
      script.async = true;
      script.onload = () => setIsLoaded(true);

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isLoaded]);

  return isLoaded;
};

export default useLoadChartScript;
