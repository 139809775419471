import {
  formatNumber,
  getPerformanceHistory,
  getSSIDetail,
  getStatistic,
  getSymbolDetail,
  NumberFormatType,
  StockSSIData,
} from '../../api/stock/api';
import useLoadChartScript from '../../hooks/useLoadChartScript';
import React, { FC, useEffect, useRef, useState } from '../../lib/teact/teact';
import StockPrice from '../middle/componentMarket/StockPrice';
import './Home.scss';

interface ChartType<T = number> {
  t?: T[];
  o?: T[];
  h?: T[];
  l?: T[];
  c?: T[];
  v?: T[];
  nextTime?: T;
}

type StateProps = {
  dataSI?: StockSSIData;
  chart?: ChartType;
};

const colors = {
  lineUp: '#34c85e',
  bgUp: '#34c85e80',
  lineDown: '#ff4242',
  bgDown: '#ff424280',
  lineRef: '#ffad0d',
  bgRef: '#ffad0d80',
  lineCe: '#f365ff',
  bgCe: '#f365ff80',
  lineFloor: '#40cbf9',
  bgFloor: '#40cbf980',
};

const StockCard: FC<StateProps> = ({ dataSI, chart }) => {
  const [color, setColor] = useState('');
  const [horizontalLineY, setHorizontalLineY] = useState(0);
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const isChartScriptLoaded = useLoadChartScript();
  const chartInstance = useRef<Chart | null>(null);
  const [statistic, setStatistic] = useState<any>(null);
  const [performance, setPerformance] = useState<any>(null);
  const [symbolDetail, setSymbolDetail] = useState<any>(null);

  useEffect(() => {
    if (!dataSI?.symbol) return;
    const statistic = async () => {
      const data = await getStatistic(dataSI?.symbol);
      setStatistic(data);
    };
    const getPerformance = async () => {
      const data = await getPerformanceHistory(dataSI?.symbol);
      setPerformance(data);
    };
    const getDetail = async () => {
      const data = await getSymbolDetail(dataSI?.symbol);
      setSymbolDetail(data.shortName);
    };

    getDetail();
    statistic();
    getPerformance();
  }, [dataSI]);

  useEffect(() => {
    if (dataSI?.basicPrice) {
      setHorizontalLineY(dataSI.basicPrice);
    }
  }, [dataSI?.basicPrice]);

  const horizontalLinePlugin = {
    id: 'horizontalLine',
    afterDraw: function (chart: {
      config: { options: { horizontalLine: { y: any; lineWidth: number; color: string } } };
      ctx: any;
      scales: { [x: string]: any };
      chartArea: { left: any; right: any };
    }) {
      if (chart.config.options.horizontalLine) {
        const ctx = chart.ctx;
        const yScale = chart.scales['y'];
        const yValue = chart.config.options.horizontalLine.y;
        const yPixel = yScale.getPixelForValue(yValue);

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(chart.chartArea.left, yPixel);
        ctx.lineTo(chart.chartArea.right, yPixel);
        ctx.lineWidth = chart.config.options.horizontalLine.lineWidth || 1;
        ctx.strokeStyle = chart.config.options.horizontalLine.color || 'yellow';
        ctx.setLineDash([5, 5]);
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  Chart.register(horizontalLinePlugin);

  useEffect(() => {
    if (isChartScriptLoaded && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx && chart?.c) {
        const borderColor: string[] = [];
        const backgroundColor: string[] = [];

        // Duyệt qua các giá trị của biểu đồ để áp dụng màu sắc cho từng điểm
        chart.c.forEach((value, index) => {
          if (value > horizontalLineY) {
            borderColor.push(colors.lineUp);
            backgroundColor.push(colors.bgUp);
          } else if (value < horizontalLineY) {
            borderColor.push(colors.lineDown);
            backgroundColor.push(colors.bgDown);
          } else {
            borderColor.push(colors.lineRef);
            backgroundColor.push(colors.bgRef);
          }
        });

        if (chartInstance.current) {
          chartInstance.current.destroy(); // Hủy bỏ biểu đồ cũ trước khi tạo mới
        }

        chartInstance.current = new (window as any).Chart(ctx, {
          type: 'line',
          data: {
            labels: chart?.t,
            datasets: [
              {
                data: chart?.c,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
                pointRadius: 0,
                pointHoverRadius: 5,
                fill: true,
                tension: 0.4,
                borderWidth: 1,
                segment: {
                  borderColor: function (context: any) {
                    const nextValue = context.p1.parsed.y;
                    return nextValue > horizontalLineY ? colors.lineUp : nextValue < horizontalLineY ? colors.lineDown : colors.lineRef;
                  },
                  backgroundColor: function (context: any) {
                    const nextValue = context.p1.parsed.y;
                    return nextValue > horizontalLineY ? colors.bgUp : nextValue < horizontalLineY ? colors.bgDown : colors.bgRef;
                  },
                },
              },
            ],
          },
          options: {
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },

              tooltip: {
                enabled: false,
                mode: 'index',
                intersect: false,
                yAlign: 'bottom',
                xAlign: 'center',
                external: function (context: { tooltip: any; chart: any }) {
                  // Lấy tooltip element hoặc tạo mới nếu chưa có
                  let tooltipEl = document.getElementById('chartjs-tooltip');
                  if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.style.position = 'fixed';
                    tooltipEl.style.pointerEvents = 'none';
                    tooltipEl.style.backgroundColor = 'rgba(51, 51, 51, 0.753)';
                    tooltipEl.style.backdropFilter = 'blur(30px) saturate(3)';
                    tooltipEl.style.borderRadius = '5px';
                    tooltipEl.style.padding = '6px';
                    tooltipEl.style.transition = '0.1s ease';
                    document.body.appendChild(tooltipEl);
                  }

                  const tooltipModel = context.tooltip;

                  // Nếu tooltip bị ẩn, ẩn element
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = '0';
                    return;
                  }

                  // Lấy nội dung của tooltip
                  const titleLines = tooltipModel.title || [];
                  const bodyLines = tooltipModel.body.map((b: { lines: any }) => b.lines);

                  let innerHtml = '<div style="text-align: right;">';

                  titleLines.forEach(function (title: any) {
                    const date = new Date(title * 1000);

                    const day = ('0' + date.getDate()).slice(-2);
                    const month = ('0' + (date.getMonth() + 1)).slice(-2);
                    const year = date.getFullYear();

                    const formatDate = `${day}/${month}/${year}`;
                    innerHtml += `<div style="font-size: 12px; color: #FFF;">${formatDate}</div>`;
                  });

                  bodyLines.forEach(function (body: any) {
                    innerHtml += `<div style="font-size: 16px; color: #FFF; fontWeight: 700;">${body}</div>`;
                  });

                  innerHtml += '</div>';
                  tooltipEl.innerHTML = innerHtml;

                  // Tính toán vị trí của tooltip
                  const tooltipPosition = context.chart.canvas.getBoundingClientRect();
                  const positionY = tooltipModel.caretY + tooltipPosition.top - tooltipEl.clientHeight - 10;
                  const positionX = tooltipModel.caretX + tooltipPosition.left - tooltipEl.clientWidth / 2;
                  // Đặt vị trí và hiển thị tooltip
                  tooltipEl.style.opacity = '1';
                  tooltipEl.style.left = positionX + 'px';
                  tooltipEl.style.top = positionY + 'px';
                },
              },
            },
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
                beginAtZero: true,
              },
            },
            hover: {
              mode: 'nearest',
              intersect: false,
            },
            horizontalLine: {
              y: horizontalLineY,
              color: 'gray',
              lineWidth: 1,
            },
          },
        });
      }
    }
  }, [isChartScriptLoaded, chart, horizontalLineY]);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.options.horizontalLine.y = horizontalLineY;
      chartInstance.current.update();
    }
  }, [horizontalLineY]);

  return (
    <div className="p-3 rounded-lg shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] cursor-pointer min-w-min">
      <div className="flex items-center mb-4 justify-between">
        <span className={`text-[20px] font-medium whitespace-nowrap ${color}`}>{dataSI?.symbol}</span>
        <span className="ml-1 truncate whitespace-nowrap text-[#9DA0A6] ">
          <span>{dataSI?.floorCode}</span>
          <span> - </span>
          <span title={`${symbolDetail}`}>{symbolDetail}</span>
        </span>
      </div>
      <div>
        <div className="flex justify-between whitespace-nowrap">
          <div className="flex w-full flex-col items-baseline max-w-[140px] [&_p]:first-of-type:leading-snug">
            <div className="flex items-center">
              <p className="font-medium text-xl">
                {
                  <StockPrice
                    currentPrice={dataSI?.matchPrice || dataSI?.estimatedPrice || dataSI?.basicPrice}
                    referencePrice={dataSI?.basicPrice}
                    ceilingPrice={dataSI?.ceilingPrice}
                    floorPrice={dataSI?.floorPrice}
                    type={NumberFormatType?.Price}
                    isBlink
                    getColor={setColor}
                  />
                }
              </p>
            </div>
            <div className="flex items-center">
              <p className={`w-fit rounded-sm p-0 bg-transparent ${color}`}>
                {dataSI && <StockPrice currentPrice={dataSI?.matchPrice - dataSI?.basicPrice} type={NumberFormatType.Changed} isBlink />}
              </p>
              <p className={`w-fit rounded-sm p-0 bg-transparent ml-1 ${color}`}>
                {dataSI && (
                  <StockPrice
                    currentPrice={((dataSI?.matchPrice - dataSI?.basicPrice) / dataSI?.basicPrice) * 100}
                    type={NumberFormatType.ChangedRatio}
                    isBlink
                  />
                )}
              </p>
            </div>
          </div>
          <div className="w-[180px] h-11">
            <div>
              <canvas
                width={180}
                height={44}
                style={{ display: 'block', boxSizing: 'border-box', height: '44px', width: '180px' } as React.CSSProperties}
                ref={chartRef}
              />
              <div
                style={
                  {
                    borderRadius: 4,
                    fontSize: 12,
                    pointerEvents: 'none',
                    position: 'absolute',
                    transform: 'translate(-50%, 0px)',
                    transition: '0.1s',
                    opacity: 0,
                    left: 391,
                    top: '680.247px',
                    padding: 6,
                    background: 'rgba(51, 51, 51, 0.753) !important',
                    backdropFilter: 'blur(30px) saturate(3) !important',
                  } as React.CSSProperties
                }></div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-7">
          <div className="flex mt-3 col-span-3">
            <div>
              {dataSI && (
                <PerformanceChange
                  label="5 ngày"
                  change={
                    (((dataSI?.matchPrice || dataSI?.estimatedPrice || dataSI?.basicPrice) - performance?.lastMonthPrice) /
                      performance?.lastMonthPrice) *
                    100
                  }
                />
              )}
            </div>
            <div className="ml-5">
              {dataSI && (
                <PerformanceChange
                  label="1 năm"
                  change={
                    (((dataSI?.matchPrice || dataSI?.estimatedPrice || dataSI?.basicPrice) - performance?.lastYearPrice) /
                      performance?.lastYearPrice) *
                    100
                  }
                />
              )}
            </div>
          </div>
          <div className="col-span-4 text-right mt-3">
            <div className="flex justify-end">
              <p className="m-0 rounded-sm bg-transparent whitespace-nowrap">
                {dataSI &&
                  formatNumber(
                    statistic?.klcplh * 1000 * (dataSI?.matchPrice || dataSI?.estimatedPrice || dataSI?.basicPrice),
                    NumberFormatType?.TotalVolume,
                  )}
              </p>
            </div>
            <p className="text-[#9DA0A6] whitespace-nowrap">Giá trị vốn hoá</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const PerformanceChange: FC<{ label: string; change: number }> = ({ label, change }) => (
  <>
    <p className={`w-fit rounded-sm p-0 bg-transparent whitespace-nowrap ${change > 0 ? 'text-[#34c85e]' : 'text-[#ff4242]'}`}>
      {change.toFixed(2)}%
    </p>
    <p className="text-left text-[#9DA0A6] whitespace-nowrap">{label}</p>
  </>
);
export default StockCard;
